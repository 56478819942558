<template>
  <div v-if="category != null">
    <page-header title="Просмотр отрасли" back>
      <category-context-menu v-if="!category.deleted" :category="category" :hook="reload" />
    </page-header>

    <q-card v-if="category.deleted" class="q-pa-lg">
      Отрасль удалена.
    </q-card>
    <q-card v-else class="t-card">
      <div class="t-header">
        <div class="t-name">{{ category.name }}</div>
      </div>

      <div v-if="category.description" class="t-field">
        <div class="t-field-key">
          Описания отрасли
        </div>
        <div class="t-field-value category-description">
          <div v-html="category.description" />
        </div>
      </div>

      <div v-if="terms !== null" class="t-field">
        <div class="t-field-key">
          Связанные слова
        </div>
        <div class="t-field-value">
          <template v-if="terms.length === 0">
            С этой отраслью пока нет связанных слов.
          </template>
          <template v-else>
            <div v-for="(letterTerms, letter) in termsByLetters" :key="letter" class="letter-terms">
              <div class="letter-terms--title">
                {{ letter }}
              </div>
              <div class="letter-terms--list">
                <div v-for="term in letterTerms" :key="term.id">
                  <router-link :to="{name: 'terms_view', params: {id: term.id}}">{{ term.name }}</router-link>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </q-card>
  </div>
</template>

<script>

import loading from '../../loading.js'
import api from '../../api/api.js'
import { api as categoriesApi } from '../../api/categories.js'
import PageHeader from '../../blocks/PageHeader.vue'
import CategoryContextMenu from '../../blocks/categories/CategoryContextMenu.vue'

export default {
  components: {
    PageHeader,
    CategoryContextMenu,
  },
  setup() {
    return {
      canEdit: ["editor", "admin"].includes(api.auth.getCurrentUser().role.id),
    }
  },
  async beforeRouteEnter (to, from, next) {
    loading.start()
    const category = await categoriesApi.find(to.params.id)
    if (!category) {
      next(false)
      return
    }
    const terms = await api.terms.findBy({category, limit: 10000})
    next(vm => {
      vm.category = category
      vm.terms = terms
    })
    loading.stop()
    loading.updateTitle(to.meta.title || '')
  },
  data() {
    return {
      category: null,
      terms: null
    }
  },
  methods: {
    async reload(category) {
      this.category = category
    },
  },
  computed: {
    termsByLetters() {
      const termsByLetters = {}

      this.terms.forEach(term => {
        const letter = term.name.substr(0, 1).toUpperCase()
        if (!termsByLetters[letter]) {
          termsByLetters[letter] = []
        }
        termsByLetters[letter].push(term)
      })

      return termsByLetters
    },
  }
}
</script>

<style lang="scss">
.category-description {
  white-space: pre;
}

.letter-terms {
  margin-bottom: 20px;

  &--title {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 22px;
    color: #2C7DDC;
  }
}
</style>
